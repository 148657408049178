import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

class Page extends React.Component {    
    render() {
        return (
            <Layout title="404 - Seite nicht gefunden">
                <SEO title="404 - Seite nicht gefunden" />

                <p>
                    Hoppla, anscheinend hast du eine Seite entdeckt, die nicht mehr existiert…
                </p>
                <p>
                    Was du jetzt machen kannst:
                </p>
                <ul>
                    <li><Link to="/">Zur Startseite</Link></li>
                    <li><Link to="/blog/">Zur Blogseite</Link></li>
                </ul>
            </Layout>
        )
    }
}

export default Page;
